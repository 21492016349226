<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div>
      <input type="number" name="qty" v-model="qty"/>
      <select name="currency" v-model="currency">
        <option value="CUP">CUP</option>
        <option value="USD">USD</option>
        <option value="ECU">EUR</option>
        <option value="MLC">MLC</option>
      </select>
    </div>
    <br>
    <easy-spinner v-if="entries.length == 0" :size="80"/>
    <div v-for="entry in entries" :key="entry.key">
      <div :style=getRowStyle(entry)>
        <span style="font-weight:'bold'">{{ entry.key }}</span>:
        <span>{{ parseFloat(entry.value).toFixed(2) }}</span>
        <hr/>
      </div>
    </div>

  </div>
</template>

<script>
  import {ExchangeV2} from './../currency'

  export default {
    name: 'CalcCurrency',
    props: {
      msg: String
    },
    data() {
      return {
        exchange: new ExchangeV2(),
        entries: [],
        currency: 'CUP',
        qty: 100
      }
    },
    created() {
      // fetch on init
      this.fetchData()
    },

    watch: {
      // re-fetch whenever currentBranch changes
      currency: 'fetchData',
      qty: 'fetchData'
    },

    methods: {
      async fetchData() {
        // fetch data
        try {
          const result = await this.exchange.convert({amount: this.qty, currency: this.currency})

          if (result[this.currency]) {
            // this.entries = Object.entries(result[this.currency]).map(([key, value]) => ({key, ...value})).filter(entry => entry.directExchange)
            this.entries = Object.entries(result[this.currency])
              .map(([key, value]) => ({key, ...value}))
              .filter(x => !isNaN(x.value))
              .sort(x => x.directExchange ? -1 : 1)
          } else {
            this.entries = []

          }
        } catch (e) {
          console.log(e)
        }

      },

      getRowStyle(entry) {
        if (!entry.directExchange) {
          return 'color: lightgray;'
        }
        return 'color: black;'
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
